import React from 'react'
import loginpage from "../../Images/login.png"
import logo from "../../Images/reveallogo.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleRight, faInfo, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { Outlet,Link, useNavigate } from 'react-router-dom'


export default function Signup() {
    const navigate = useNavigate();

    const controllSubmit = ()=>{
        navigate("/business-info")
    }
  return (
    <div className='flex'>
        <div className='w-1/2 relative h-screen hidden lg:flex justify-center items-center' style={{background: "linear-gradient(to bottom, rgba(212,224,250,1) 0%,rgba(244,236,247,1) 50%,rgba(244,236,247,1) 50%,rgba(251,247,252,1) 100%)"}}>
            <img className='w-full' src={loginpage} alt='login'/>
            <div className='logincover absolute top-10'>
                <h2>
                    <strong>Background</strong>
                    Check made simple
                </h2>
            </div>
        </div>
        <div className='w-full lg:w-1/2 p-4 lg:p-16 ' style={{paddingTop:'20px'}}>
            <div className='flex justify-start pt-3 lg:pt-0'>
                <img src={logo} alt='Logo' />
            </div>
            <div className='mt-2 text-left'>
                <h6 className='text-2xl' style={{color:"#25253e"}}><strong className='font-extrabold'>Sign Up</strong> for an Account!</h6>
                <p className='mt-3'>Unleash the power of secure & FCRA compliant background checks and make informed decisions with our reliable and customizable service.</p>
            </div>
            <div className='flex mt-10 justify-start w-full flex-wrap space-y-5 lg:space-y-0'>
                <div className='w-full lg:w-10/12'>
                    <p className='text-left'>First Name <span className='text-red-500'>*</span></p>
                    <input className='p-2 w-full border-2 rounded-md border-red-200' type='email' placeholder='John *' /><br></br><br></br>

                    <p className='text-left'>Last Name <span className='text-red-500'>*</span></p>
                    <input className='p-2 w-full border-2 rounded-md border-red-200' type='email' placeholder='Miller *' /><br></br>
                    
                    <br></br>

                    <p className='text-left'>Email Address <span className='text-red-500'>*</span></p>
                    <input className='p-2 w-full border-2 rounded-md border-red-200' type='email' placeholder='john12@gmail.com *' /><br></br>
                    <p className='text-sm text-left' style={{color:'#7a8798', fontSize:'10px'}}> <FontAwesomeIcon icon={faInfoCircle} /> This will also be your username when you return in the future</p>
                    
                    <br></br>

                    <p className='text-left'>Password <span className='text-red-500'>*</span></p>
                    <input className='p-2 w-full border-2 rounded-md border-red-200' type='email' placeholder='*********' /><br></br><br></br>



                    
                    <div className='flex justify-start'>
                        <input onClick={controllSubmit} className='p-2 bg-blue-700 text-white w-full lg:w-5/12 border-2 rounded-md' type='submit' value={'GET STARTED'} /><br></br><br></br>
                   
                    </div><br></br>
                    <p className='text-left'>Already have an account ? <Link className='text-blue-700 font-bold' to={'/login'}>Log in</Link></p>
                    
                </div>
            </div>
            <div className='h-56 lg:h-0'>

            </div>
        </div>
    </div>
  )
}
