import { apiURL } from "../../app/lib";

// A mock function to mimic making an async request for data
export function fetchLogin(data) {
  const result = fetch(apiURL+"/adminlogin",{
    method:"POST",
    headers: {
      "Content-Type": "application/json"
    },
    body:JSON.stringify(data)
  })
  .then((res)=>res.json())
  .then((result)=>{
    return result
  });

  return result;
}
