import React, { useState } from 'react'
import loginpage from "../../Images/login-page.jpg"
import logo from "../../Images/reveallogo.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons'
import { Outlet,Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { loginAsync } from '../../features/login/loginSlice'


export default function Login() {
    const [email, setemail] = useState('');
    const [password, setpassword] = useState('');
    const [isLoading, setisLoading] = useState(false);
    const [errormessage, seterrormessage] = useState(null);
    const dispatch = useDispatch();
    // const {user} = useSelector(state=>state.login)

    const handleSubmit = ()=>{
        setisLoading(true)
        if(!email&&!password){
            setisLoading(false)
            seterrormessage("Empty Input! 😢")
            return 0;
        }
        const data = {email,password};
        dispatch(loginAsync(data)).unwrap().then((result)=>{
            if(result.status!==200){
                seterrormessage(result?.message)
                setisLoading(false)
                return 0;
            }
            sessionStorage.setItem("login",JSON.stringify(result))
            setisLoading(false)
        })
    }
  return (
    <div className='flex'>
        <div className='w-1/2 h-screen hidden lg:flex justify-center items-center' style={{background: "#fff"}}>
            <img className='w-full' src={loginpage} alt='login'/>
        </div>
        <div className='w-full lg:w-1/2 p-10'>
            <div className='flex justify-center pt-20'>
                <img src={logo} alt='Logo' />
            </div>
            <div className='mt-5'>
                <h6 className='text-2xl font-bold' style={{color:"#25253e"}}>Welcome Back!</h6>
                <p>Please enter your login details</p>
            </div>
            <div className='flex mt-14 justify-between flex-wrap space-y-5 lg:space-y-0'>
                <div className='w-full'>
                    {errormessage&&<p className='text-red-600'>{errormessage}</p>}
                    <input onChange={(e)=>{setemail(e.target.value); seterrormessage(null)}} className='p-2 w-full lg:w-8/12 border-2 rounded-md' type='email' placeholder='Enter your email *' /><br></br>
                    <div className='flex justify-center'>
                        <div className='w-full lg:w-8/12 relative'>
                            <input onChange={(e)=>{setpassword(e.target.value);seterrormessage(null)}} className='p-2 my-3 w-full border-2 rounded-md' type='password' placeholder='Enter your password *' />
                            {/* <Link className='absolute right-3 top-5 font-bold text-red-700' to={'/forgot-password'}>Forgot?</Link> */}
                        </div>
                    </div>
                    <br></br>
                    
                    <input onClick={handleSubmit} disabled={isLoading} className='p-2 bg-blue-700 text-white w-full lg:w-3/12 border-2 rounded-md' type='submit' value={isLoading?"Loading...":'LOGIN'} /><br></br><br></br>

                    <p>Don't have an account? <Link className='text-blue-700' to={"/getstarted"}>Get Started</Link></p> 
                    <Outlet />
                </div>
            </div>
            <div className='h-56 lg:h-0'>

            </div>
        </div>
    </div>
  )
}
