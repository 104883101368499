import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React from 'react';


const SubscriptionsTable = ({ EditPackage, DeletePackage, subscriptions }) => {
  return (
    <div>
      <table className='w-full'>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Price</th>
            <th>Type</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {subscriptions.map((subscription) => (
            <tr className='border-b' key={subscription.id}>
              <td>{subscription.id}</td>
              <td>{subscription.name}</td>
              <td>
                {subscription.price}
                <sub className='text-red-400'> {subscription.discount_price}</sub>
              </td>
              <td>
                <p>{subscription.package_type}</p>
                <p className='text-gray-500'>{moment(subscription.created_at).format("MMM Do YY") }</p>
              </td>
              <td>
                <p className='text-xs  bg-blue-200 text-blue-700 border border-blue-600 px-3 py-1 rounded-xl w-fit'>{subscription.isactive==='1'?"Active":"Inactive"}</p>
              </td>
              <td className='flex space-x-2'>
                <p onClick={()=>EditPackage(subscription)} className='text-xs cursor-pointer bg-green-200 text-green-700 border border-green-600 px-3 py-1 rounded-xl w-fit'><FontAwesomeIcon icon={faEdit} /> Edit</p>
                <p onClick={()=>DeletePackage(subscription.id)} className='text-xs cursor-pointer bg-red-200  text-red-700 border border-red-600 px-3 py-1 rounded-xl w-fit'><FontAwesomeIcon icon={faTrash} /> Delete</p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SubscriptionsTable;
