import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchLogin } from './loginAPI';

const initialState = {
  user:null,
  isError:false,
  isLoading:false,
  error:null,
  session:true
};

export const loginAsync = createAsyncThunk(
  'user/login',
  async (data) => {
    const response = await fetchLogin(data);
    return response;
  }
);

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    login: (state,action) => {
      state.user = action.payload;
    },
    logout:(state)=>{
      state.user=null
    },
    sessionCheck:(state,action)=>{
      state.session=action.payload
    }
    
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        state.isError = false;
        state.isLoading = false;
        state.user = action.payload;
        state.error=null;
      })
      .addCase(loginAsync.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.user = null;
        state.error=action.error;
      });
  },
});

export const { login, logout } = loginSlice.actions;


export default loginSlice.reducer;
