import React, { useCallback, useEffect, useState } from 'react';
import Sidebar from './sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-regular-svg-icons';
import { faArrowRight, faUser, faUsers } from '@fortawesome/free-solid-svg-icons';
import SimpleMDE, { SimpleMdeReact } from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";
import MarkdownIt from 'markdown-it';
import { apiURL } from '../../app/lib';
import { useSelector } from 'react-redux';
import { isjwtExpire } from '../../app/jwtcheck';
import { ToastContainer, toast } from 'react-toastify';
import Message from '../headers/Message';
import SubscriptionsTable from './subscriptions/Subscriptiontable';
import htmlToMarkdown from '@wcj/html-to-markdown';
import TasksTable from './tasks/tasksTable';
import UserTable from './users/usersTable';

const Users = () => {
    const [value, setValue] = useState("");
    const [packages, setpackages] = useState([]);
    const [isLoadingSubmit, setisLoadingSubmit] = useState(false);
    const [isContentLoading, setisContentLoading] = useState(false);
    const [subscription_group, setsubscription_group] = useState('');
    const [isEditModon, setisEditModon] = useState(false);
    const {user} = useSelector(state=>state.login)
    
    
   
    const fetchAllPackage = ()=>{
        setisContentLoading(true)
        fetch(apiURL+"/common",{
            method:'POST',
            headers:{
                "Content-Type":"application/json",
                "Authorization":user?.token
            },
            body:JSON.stringify({
                data:"",
                sql:"SELECT * FROM users ORDER BY id DESC"
            })
        })
        .then((res)=>res.json())
        .then((result)=>{
            if(result.status===200){
                console.log(result.result);
                setpackages(result?.result)
            }else{
                toast(result?.message)
            }
            setisContentLoading(false)
            
        })
        .catch(()=>{
            toast("Package Not Fetch!")
            setisContentLoading(false)
        })
    }

    const DeletePackage = (id)=>{
        const confirmDelete = window.confirm("Are you sure you want to delete?");
        if(!confirmDelete){
            return 0;
        }
        fetch(apiURL+"/delete-tasks/"+id,{
            method:'POST',
            headers:{
                "Content-Type":"application/json",
                "Authorization":user?.token
            },
            body:JSON.stringify({user_id:user?.result[0]?.id})
        })
        .then((res)=>res.json())
        .then((result)=>{
           
            if(result.status===200){
                toast(result?.message)
                fetchAllPackage()
            }else{
                toast(result?.message)
            }
           
            
        })
        .catch(()=>{
            toast("Task Not Deleted!")
            
        })
    }

    const HandleEdit = (id)=>{
        
        setisLoadingSubmit(true)
        fetch(apiURL+"/edit-task/"+id,{
            method:'POST',
            headers:{
                "Content-Type":"application/json",
                "Authorization":user?.token
            },
            body:JSON.stringify()
        })
        .then((res)=>res.json())
        .then((result)=>{
            if(result.status===200){
                toast(result?.message)
                
                setValue('')
                setisEditModon(false)
                fetchAllPackage()
            }else{
                toast(result?.message)
            }
            setisLoadingSubmit(false)
            
        })
        .catch(()=>{
            toast("Package Not Saved!")
            setisLoadingSubmit(false)
        })
    }

    const EditPackage = (info)=>{
        htmlToMarkdown({ html: info.description }).then((r)=>{
            setisEditModon(true)
            setValue(r)
            setsubscription_group(info.subcription_group)
            
            // console.log(r);
        })
        
        

    }
    
 


      useEffect(() => {
        fetchAllPackage()
      }, []);
    return (
        <div className='flex bg-[#f7f9fc]'>
            
            <Sidebar/>
            
            <div className='w-full lg:w-4/5 p-5 mt-16 lg:p-10 text-left'>
                <div className='border-b pb-2 flex justify-between'>
                    <p className='text-xl font-bold'>Users </p>
                    <FontAwesomeIcon size='xl' icon={faUsers} />
                </div>
                <br></br>
                <br></br>
                
                <div className='relative'>
                    {
                        isContentLoading&&
                        <div className='absolute bg-[rgba(0,0,0,0.2)] w-full left-0 top-0 h-full z-50 p-10'><Message message={"Loading..."} /></div>
                    }
                    {
                        !isContentLoading&&
                        <UserTable EditPackage={EditPackage} DeletePackage={DeletePackage} subscriptions={packages} fetchAllPackage={fetchAllPackage} />
                    }
                </div>

            </div>
            


        </div>
    );
}

export default Users;
