import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React from 'react';


const TasksTable = ({ EditPackage, DeletePackage, subscriptions }) => {
  return (
    <div>
      <table className='w-full'>
        <thead>
          <tr>
            <th>ID</th>
            <th>Title</th>
            <th>Points</th>
            <th>Limit</th>
            <th>Category</th>
            <th>Subscription Group</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {subscriptions.map((subscription,index) => (
            <tr className='border-b' key={index}>
              <td>{index+1}</td>
              <td>{subscription.task_title}</td>
              <td>
                {subscription.points}
               
              </td>
              <tb>
                <p className='px-2 m-1 py-1 bg-red-500 border rounded-xl text-white text-[9px] w-fit'>{subscription?.isLimited===0?'Unlimited':"Limited"}</p>
                {subscription?.isLimited===1&&<p className='text-xs'>Submiited {subscription?.total_submit} out of {subscription?.task_limit}</p>}
                {subscription?.isLimited===0&&<p className='text-xs'>Submiited {subscription?.total_submit} out of <span className='text-xl'>∞</span></p>}
              </tb>
              <td>
                <p>{subscription.category}</p>
                <p className='text-gray-500'>{moment(subscription.created_at).format("MMM Do YY") }</p>
              </td>
              <td>
                <div className='flex flex-wrap'>
                  {
                    subscription.subcription_group&&subscription.subcription_group.split(",").map((packageName)=>{
                        return <p className='px-2 m-1 py-1 bg-blue-500 border rounded-xl text-white text-xs w-fit'>{packageName}</p>
                    })
                  }
                </div>
              </td>
              <td className='flex space-x-2'>
                <p onClick={()=>EditPackage(subscription)} className='text-xs cursor-pointer bg-green-200 text-green-700 border border-green-600 px-3 py-1 rounded-xl w-fit'><FontAwesomeIcon icon={faEdit} /> Edit</p>
                <p onClick={()=>DeletePackage(subscription.id)} className='text-xs cursor-pointer bg-red-200  text-red-700 border border-red-600 px-3 py-1 rounded-xl w-fit'><FontAwesomeIcon icon={faTrash} /> Delete</p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TasksTable;
