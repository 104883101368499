import React from 'react';
import Sidebar from './sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-regular-svg-icons';
import { faAdd, faArrowRight, faGear, faLock, faUsers } from '@fortawesome/free-solid-svg-icons';

const TeamAccess = () => {
    const handleHubmit=()=>{

    }
    return (
        <div className='flex bg-[#f7f9fc]'>
            <div className='hidden lg:block w-1/4 bg-white'>
                <Sidebar/>
            </div>
            <div className='w-full lg:w-4/5 p-5 lg:p-10 text-left'>
                <div className='border-b pb-2 flex justify-between'>
                    <div>
                        <p className='text-xl font-bold'>Team Access </p>
                        <p>Manage the users who have access to your account.</p>
                    </div>
                    <FontAwesomeIcon size='xl' icon={faUsers} />
                </div>
                <br></br>


                <div className='space-y-4'>
                    <div className='flex justify-start'>
                        <div onClick={handleHubmit} className='p-2 cursor-pointer bg-blue-700 text-white w-full lg:w-2/12 border-2 rounded-md flex justify-center items-center'>
                            <FontAwesomeIcon icon={faAdd} />
                            <p className='mr-2 ml-2'> Invite User</p>
                        </div>
                    </div>
                    <br></br>
                    <div className='space-y-4'>
                        <div className='flex justify-between items-center border bg-white rounded-md p-5'>
                            <div className='flex items-center'>
                                <img className='rounded-full mr-5' src='http://approvals.gtectsystems.com/reveal/webapp/images/team1.png' alt='user' />
                                <div >
                                    <p className='font-bold text-md'>RON Kilgarlin Jr-CEO</p>
                                    <p className='text-xs'>Active</p>
                                </div>
                            </div>

                            <div className='flex items-center'>
                                <p className='px-3 mr-4 py-1 bg-blue-100 border border-blue-500 rounded-2xl text-xs'>OWNER</p>
                            </div>
                        </div>

                        <div className='flex justify-between items-center border bg-white rounded-md p-5'>
                            <div className='flex items-center'>
                                <img className='rounded-full mr-5' src='http://approvals.gtectsystems.com/reveal/webapp/images/team1.png' alt='user' />
                                <div >
                                    <p className='font-bold text-md'>RON Kilgarlin Jr-CEO</p>
                                    <p className='text-xs'>Active</p>
                                </div>
                            </div>

                            <div className='flex items-center'>
                                <p className='px-3 mr-4 py-1 bg-blue-100 border border-blue-500 rounded-2xl text-xs'>Sales</p>
                                <FontAwesomeIcon icon={faGear} />
                            </div>
                        </div>

                        

                        <div className='flex justify-between items-center border bg-white rounded-md p-5'>
                            <div className='flex items-center'>
                                <div className='h-16 w-16 border mr-5 bg-blue-200 rounded-full flex justify-center items-center'>
                                    <p>RK</p>
                                </div>
                                <div >
                                    <p className='font-bold text-md'>RON Kilgarlin Jr-CEO</p>
                                    <p className='text-xs'>Active</p>
                                </div>
                            </div>

                            <div className='flex items-center'>
                                <p className='px-3 mr-4 py-1 bg-blue-100 border border-blue-500 rounded-2xl text-xs'>Sales</p>
                                <FontAwesomeIcon icon={faGear} />
                            </div>
                        </div>
                    </div>
                    

                    
                </div>

                <br></br>
                

            </div>
        </div>
    );
}

export default TeamAccess;
