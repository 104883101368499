import {jwtDecode} from 'jwt-decode';

export const isjwtExpire = (token) => {
  try {
    // Decode the token to extract the expiration time
    
    const decodedToken = jwtDecode(token);

    // Get the expiration time from the decoded token
    const expirationTime = decodedToken.exp;

    // Convert expiration time from Unix timestamp to human-readable format
    const expirationDate = new Date(expirationTime * 1000);

    // Current time
    const currentTime = new Date();

    // Check if the token is expired
    const isTokenExpired = currentTime.getTime() > expirationDate.getTime();

    return isTokenExpired;
  } catch (error) {
    // console.error("Error decoding token:", JSON.stringify(error));
    return true; // Consider expired if there's an error decoding
  }
};
