import { faCheckSquare, faEdit, faEye } from '@fortawesome/free-regular-svg-icons';
import { faCancel, faRetweet, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useEffect, useState } from 'react';


const PayRequestTable = ({ EditPackage, DeletePackage, subscriptions,updateTaskStatus }) => {
  const [selectedTask, setselectedTask] = useState(null);
  const [showModal, setshowModal] = useState(false);
  const [comments, setcomments] = useState('');
 

  const sanitizeHtml = (html) => {
    
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.innerHTML;
  };

  const onOffModal = (task=null)=>{
    setshowModal(!showModal)
    if(!task){
      setselectedTask(null)
    }else{
      setselectedTask(task)
      
    }
    
  }
  useEffect(() => {
    
  }, []);
  return (
    <div className='relative'>


      {/* Modal */}
      {showModal&&selectedTask?.id&&<div className='fixed top-0 left-0 w-full h-screen z-50 p-2 lg:p-5 bg-[rgba(0,0,0,0.5)] flex justify-center'>
        <div className='bg-white transition-all w-11/12 p-3 lg:p-5 rounded-md h-fit max-h-[90vh] overflow-y-auto'>
          <div className='flex justify-between items-center p-2 pb-3 border-b'>
            <div>
              <h1 className='text-xl'>Withdraw Requeste</h1>
           
            </div>
            <p className='cursor-pointer text-xl' onClick={onOffModal}>&times;</p>
          </div>

          
           

          <div className='my-5'>
            <h1 className='text-xl text-red-700 mb-2'>About User</h1>
            <hr></hr>
            <br></br>
            <div>
              <p>Name: {selectedTask?.full_name}</p>
              <p>Email: {selectedTask?.email}</p>
              <p>Phone: {selectedTask?.phone}</p>
              <p>Subscribed In: {selectedTask?.subscription}</p>
              <p>Total Points: {selectedTask?.points}</p>
              <p>Requested to Withdraw Points: {selectedTask?.amount}</p>
              
            </div>
            
            <br></br>
            <h1 className='text-xl text-red-700 mb-2'>Requested Into</h1>
            <hr></hr>
            <br></br>
            <div className=''>
              <p>Account No.: {selectedTask?.account_number}</p>
                <p>Bank: <b>{selectedTask.bank_name}</b></p>
               
                <p>Amount: <b>{selectedTask.amount/10}</b> in BDT</p>
              <p>Requested at: {selectedTask?.requested_at}</p>
            </div><br></br>
            <div className=' bg-white shadow-md m-2 p-5 border'>

              <textarea onChange={(e)=>setcomments(e.target.value)} className='h-32 p-3 w-full border-2 ' placeholder='Write Comments here.....'></textarea>

              <div className='flex space-x-2 my-4'>
                <p onClick={()=>updateTaskStatus(selectedTask?.user_id,"completed",selectedTask?.w_id,selectedTask?.amount,comments)} className='text-xs cursor-pointer bg-green-200 text-green-700 border border-green-600 px-3 py-1 rounded-xl w-fit'><FontAwesomeIcon icon={faCheckSquare} /> Mark As Paid</p>

                <p onClick={()=>updateTaskStatus(selectedTask?.user_id,"failed",selectedTask?.w_id,selectedTask?.amount,comments)} className='text-xs cursor-pointer bg-red-200  text-red-700 border border-red-600 px-3 py-1 rounded-xl w-fit'><FontAwesomeIcon icon={faCancel} /> Mark as Failed </p>

                <p onClick={()=>updateTaskStatus(selectedTask?.user_id,"Rejected",selectedTask?.w_id,selectedTask?.amount,comments)} className='text-xs cursor-pointer bg-blue-200  text-blue-700 border border-blue-600 px-3 py-1 rounded-xl w-fit'><FontAwesomeIcon icon={faRetweet} /> Mark as Rejected</p>


              </div>
              <p className='text-xs'>You made this comment: </p>
              {selectedTask?.comments&&<p className='text-lg text-red-500'>{selectedTask?.comments?selectedTask?.comments:"Null"}</p>}
              
            </div>
          </div>


        </div>
      </div>}
      {/* Modal */}


      <table className='w-full overflow-x-auto'>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Contact</th>
            <th>Subscriptions</th>
            <th>Bank Details</th>
            
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {subscriptions.map((subscription, index) => (
            <tr className='border-b' key={index}>
              <td>{index+1}</td>
              <td>
                {subscription.full_name}
                <p className='text-xs text-green-600'>Requested on: {subscription?. requested_at}</p>
              
              </td>
              <td className='text-xs'>
                <p className='text-blue-500'>Email: {subscription.email}</p>
                <p className='text-red-500'>Phone: {subscription.phone}</p>
                <p className='text-green-500'>DOB: {subscription.dob}</p>
              </td>
              <td className='text-xs '>
                <p className='text-md '>{subscription?.subscription}</p>
                <p className='text-xs cursor-pointer bg-blue-200  text-blue-700 border border-blue-600 px-3 py-[2px] rounded-xl w-fit'>{subscription?.status}</p>
             
              </td>
              <td>
                <div className='text-xs'>
                <p>Account No.: {subscription?.account_number}</p>
                <p>Bank: <b>{subscription.bank_name}</b></p>
                <p>Amount: <b>{subscription.amount}</b></p>
                </div>
              </td>
              <td className='flex space-x-2'>
                <p onClick={()=>onOffModal(subscription)} className='text-xs cursor-pointer bg-green-200 text-green-700 border border-green-600 px-3 py-1 rounded-xl w-fit'><FontAwesomeIcon icon={faEye} /> Preview</p>

                {/* <p onClick={()=>DeletePackage(subscription?.p_id)} className='text-xs cursor-pointer bg-red-200  text-red-700 border border-red-600 px-3 py-1 rounded-xl w-fit'><FontAwesomeIcon icon={faTrash} /> Delete</p> */}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PayRequestTable;
