import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons'

import { useNavigate  } from 'react-router-dom'

const SelectUser = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        // Navigate to a different route when a button is clicked
        navigate("/signup")
    };
    return (
        <div>
            <div className='mt-5'>
                <h6 className='text-2xl font-bold' style={{color:"#25253e"}}>Create Your Free Account</h6>
                <p>select your account type to get started.</p>
            </div>
            <div className='flex mt-14 justify-between flex-wrap space-y-5 lg:space-y-0'>
                <div onClick={handleClick} className='text-center p-5 bg-blue-100 w-full lg:w-72 hover:bg-blue-600 transition-colors hover:text-white company rounded-md cursor-pointer' style={{border: "1px solid #e5e5eb;"}}>
                    <h6 className='text-xl font-bold text-blue-600' >Company</h6>
                    <p>If you are business and like to run background on an employee</p>
                    <p className='mt-4'><FontAwesomeIcon size="xl" icon={faArrowAltCircleRight} /></p>
                </div>

                <div className='text-center p-5 bg-blue-100 w-full lg:w-72 hover:bg-blue-600 transition-colors hover:text-white company rounded-md cursor-pointer' style={{border: "1px solid #e5e5eb;"}}>
                    <h6 className='text-xl font-bold text-blue-600' >Individual</h6>
                    <p>If you are individual and like to run background on an employee</p>
                    <p className='mt-4'><FontAwesomeIcon size="xl" icon={faArrowAltCircleRight} /></p>
                </div>
            </div>
        </div>
    );
}

export default SelectUser;
