import { faEdit, faEye } from '@fortawesome/free-regular-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { apiURL } from '../../../app/lib';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';


const CategoryTable = ({ EditPackage, DeletePackage, elements }) => {
  const [totalTaskUnderCategory, settotalTaskUnderCategory] = useState(0);
  const [selectedId, setselectedId] = useState(null);
  const {user} = useSelector(state=>state.login)
  const [isContentLoading, setisContentLoading] = useState(false);
 
  const fetchAllCategory = (category)=>{
    setisContentLoading(true)
    fetch(apiURL+"/common",{
        method:'POST',
        headers:{
            "Content-Type":"application/json",
            "Authorization":user?.token
        },
        body:JSON.stringify({
            data:"",
            sql:`SELECT * FROM tasks WHERE category='${category}'`
        })
    })
    .then((res)=>res.json())
    .then((result)=>{
        if(result.status===200){
            console.log(result.result);
            settotalTaskUnderCategory(result?.result)
        }else{
            toast(result?.message)
        }
        setisContentLoading(false)
        
    })
    .catch(()=>{
        toast("Package Not Fetch!")
        setisContentLoading(false)
    })
  }
  useEffect(() => {
    
  }, []);
  return (
    <div>
      <table className='w-full'>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Total Task</th>
            <th>Created_at</th>
         
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {elements.map((element, index) => (
            <tr className='border-b' key={index}>
              <td>{index+1}</td>
              <td>{element?.name}</td>
              <td className='text-green-500'>
                {selectedId!==element?.id&&<p className='cursor-pointer' onClick={()=>{
                  fetchAllCategory(element?.name)
                  setselectedId(element?.id)
                  }}>See Total <FontAwesomeIcon icon={faEye} /></p>}
                  {selectedId===element?.id&&<p>{totalTaskUnderCategory?.length}</p>}
               
              </td>
              <td>
                <p className='text-gray-500'>{moment(element?.created_at).format("MMM Do YY") }</p>
              </td>
              
              <td className='flex space-x-2 items-center'>
                <p onClick={()=>EditPackage(element)} className='text-xs cursor-pointer bg-green-200 text-green-700 border border-green-600 px-3 py-1 rounded-xl w-fit'><FontAwesomeIcon icon={faEdit} /> Edit</p>
                <p onClick={()=>DeletePackage(element?.id)} className='text-xs cursor-pointer bg-red-200  text-red-700 border border-red-600 px-3 py-1 rounded-xl w-fit'><FontAwesomeIcon icon={faTrash} /> Delete</p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CategoryTable;
