import React, { useCallback, useEffect, useState } from 'react';
import Sidebar from './sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-regular-svg-icons';
import { faArrowRight, faUser } from '@fortawesome/free-solid-svg-icons';
import SimpleMDE, { SimpleMdeReact } from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";
import MarkdownIt from 'markdown-it';
import { apiURL } from '../../app/lib';
import { useSelector } from 'react-redux';
import { isjwtExpire } from '../../app/jwtcheck';
import { ToastContainer, toast } from 'react-toastify';
import Message from '../headers/Message';
import SubscriptionsTable from './subscriptions/Subscriptiontable';
import htmlToMarkdown from '@wcj/html-to-markdown';
import TasksTable from './tasks/tasksTable';
import CategoryTable from './categories/table';

const Category = () => {
    const [value, setValue] = useState("");
    const [packages, setpackages] = useState([]);
    const [isLoadingSubmit, setisLoadingSubmit] = useState(false);
    const [isContentLoading, setisContentLoading] = useState(false);
    const [subscription_group, setsubscription_group] = useState('');
    const [isEditModon, setisEditModon] = useState(false);
    const {user} = useSelector(state=>state.login)
    const [categoryName, setcategoryName] = useState('');
    const [categoryID, setcategoryID] = useState(0);
    
    const handleHubmit=()=>{
        
        if(categoryName===''){
            toast("Empty Input Not Accepted!")
            return 0;
        }
        
        
        setisLoadingSubmit(true)
        fetch(apiURL+"/common",{
            method:'POST',
            headers:{
                "Content-Type":"application/json",
                "Authorization":user?.token
            },
            body:JSON.stringify({
                data:"",
                sql:`INSERT INTO categories (name) VALUES ('${categoryName}')`
            })
        })
        .then((res)=>res.json())
        .then((result)=>{
            if(result.status===200){
                toast(result.message)
                setcategoryName('')
                fetchAllPackage()
            }else{
                console.log(result);
                toast(result?.message)
            }
            setisLoadingSubmit(false)
            
        })
        .catch(()=>{
            toast("Package Not Saved!")
            setisLoadingSubmit(false)
        })
      

    }
    
    const fetchAllPackage = ()=>{
        setisContentLoading(true)
        fetch(apiURL+"/common",{
            method:'POST',
            headers:{
                "Content-Type":"application/json",
                "Authorization":user?.token
            },
            body:JSON.stringify({
                data:"",
                sql:`SELECT * FROM categories`
            })
        })
        .then((res)=>res.json())
        .then((result)=>{
            if(result.status===200){
                console.log(result.result);
                setpackages(result?.result)
            }else{
                toast(result?.message)
            }
            setisContentLoading(false)
            
        })
        .catch(()=>{
            toast("Package Not Fetch!")
            setisContentLoading(false)
        })
    }

    const DeletePackage = (id)=>{
        const confirmDelete = window.confirm("Are you sure you want to delete?");
        if(!confirmDelete){
            return 0;
        }
        fetch(apiURL+"/common",{
            method:'POST',
            headers:{
                "Content-Type":"application/json",
                "Authorization":user?.token
            },
            body:JSON.stringify({
                data:"",
                sql:`DELETE FROM categories WHERE id=${id}`
            })
        })
        .then((res)=>res.json())
        .then((result)=>{
           
            if(result.status===200){
                toast(result?.message)
                fetchAllPackage()
            }else{
                toast(result?.message)
            }
           
            
        })
        .catch(()=>{
            toast("Task Not Deleted!")
            
        })
    }

    const HandleEdit = ()=>{
        if(categoryName===''){
            toast("Empty Input Not Accepted!")
            return 0;
        }
        setisLoadingSubmit(true)
        fetch(apiURL+"/common",{
            method:'POST',
            headers:{
                "Content-Type":"application/json",
                "Authorization":user?.token
            },
            body:JSON.stringify({
                data:"",
                sql:`UPDATE categories SET name='${categoryName}' WHERE id=${categoryID}`
            })
        })
        .then((res)=>res.json())
        .then((result)=>{
            if(result.status===200){
                toast(result?.message)
                setcategoryID(0)
                setcategoryName('')
                setisEditModon(false)
                fetchAllPackage()
            }else{
                toast(result?.message)
            }
            setisLoadingSubmit(false)
            
        })
        .catch(()=>{
            toast("Package Not Saved!")
            setisLoadingSubmit(false)
        })
    }

    const EditPackage = (info)=>{
        setisEditModon(true)
        setcategoryID(info?.id)
        setcategoryName(info?.name)
    }
    const cancelEdit = ()=>{
        setcategoryID(0)
        setisEditModon(false)
        setcategoryName('')
    }
    
  

    

      useEffect(() => {
        fetchAllPackage()
      }, []);
    return (
        <div className='flex bg-[#f7f9fc]'>
            
            <Sidebar/>
            
            <div className='w-full lg:w-4/5 p-5 mt-16 lg:p-10 text-left'>
                <div className='border-b pb-2 flex justify-between'>
                    <p className='text-xl font-bold'>Add Category </p>
                    <FontAwesomeIcon size='xl' icon={faUser} />
                </div>
                <br></br>
               


                

                <div className='space-y-4'>
                    <div className='flex justify-between flex-wrap'>
                        <div className='w-full'>
                            <p>Category Name:</p>
                            <input value={categoryName} onChange={(e)=>setcategoryName(e.target.value)} className='p-3 w-full border-2 rounded-md border-gray-200' type='text' placeholder='Facebook Task' />
                        </div>
                        
                    </div>
                    
                    

                    
                    
                </div>

                <br></br>
                <div className='flex justify-start'>
                    {!isEditModon?<div  disabled={isLoadingSubmit} onClick={handleHubmit} className='p-3 cursor-pointer bg-blue-700 text-white w-full lg:w-3/12 border-2 rounded-md flex justify-center items-center'>
                        <p className='mr-2 uppercase'>{isLoadingSubmit?'Loading...':'Save Changes'}</p>
                        
                    </div>:
                    <div className='w-full flex space-x-5'>
                        <div  disabled={isLoadingSubmit} onClick={HandleEdit} className='p-3 cursor-pointer bg-green-700 text-white w-full lg:w-3/12 border-2 rounded-md flex justify-center items-center'>
                            <p className='mr-2 uppercase'>{isLoadingSubmit?'Loading...':'Save Edits'}</p>
                            
                        </div>
                        <button onClick={cancelEdit}>Cancel Edit</button>
                    </div>
                    }
                </div>
                <br></br>
                <hr></hr>
                <br></br>
                <div className='border-b pb-2 flex justify-between'>
                    <p className='text-xl font-bold'> All Tasks ({packages?.length})</p>
                    
                    <FontAwesomeIcon size='xl' icon={faUser} />
                </div>
                <div className='relative'>
                    {
                        isContentLoading&&
                        <div className='absolute bg-[rgba(0,0,0,0.2)] w-full left-0 top-0 h-full z-50 p-10'><Message message={"Loading..."} /></div>
                    }
                    {
                        !isContentLoading&&
                        <CategoryTable EditPackage={EditPackage} DeletePackage={DeletePackage} elements={packages} />
                    }
                </div>

            </div>
            


        </div>
    );
}

export default Category;
