import React from 'react';
import Sidebar from './sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-regular-svg-icons';
import { faArrowRight, faMapMarker, faUser } from '@fortawesome/free-solid-svg-icons';

const Address = () => {
    const handleHubmit=()=>{

    }
    return (
        <div className='flex bg-[#f7f9fc]'>
            <div className='hidden lg:block w-1/4 bg-white'>
                <Sidebar/>
            </div>
            <div className='w-full lg:w-4/5 p-5 lg:p-10 text-left'>
                <div className='border-b pb-2 flex justify-between'>
                    <p className='text-xl font-bold'>Address </p>
                    <FontAwesomeIcon size='xl' icon={faMapMarker} />
                </div>
                <br></br>


                
                <div className='space-y-4'>
                    <p className='text-xl font-bold mb-4'>Company Address</p>

                    <div className='flex justify-between flex-wrap'>
                        <div className='w-full lg:w-[49%]'>
                            <input className='p-3 w-full border-2 rounded-md border-gray-200' type='text' placeholder='Address 1' />
                        </div>
                        <div className='w-full lg:w-[49%]'>
                            <input className='p-3 w-full border-2 rounded-md border-gray-200' type='text' placeholder='Address 2' />
                        </div>
                        
                    </div>
                    

                    <div className='flex justify-between flex-wrap'>
                        <div className='w-full lg:w-[49%]'>
                            
                            <input className='p-3 w-full border-2 rounded-md border-gray-200' type='text' placeholder='City' />
                        </div>
                        <div className='w-full lg:w-[49%]'>
                            <input className='p-3 w-full border-2 rounded-md border-gray-200' type='text' placeholder='Zip Code' />
                        </div>
                        
                    </div>
                    
                    <div className='flex justify-between flex-wrap'>
                        <div className='w-full lg:w-[49%]'>
                            
                            <select className='bg-white p-3 w-full border-2 rounded-md border-gray-200' type='text' placeholder=''>
                                
                                <option value={'Active'}>Alabama</option>
                                <option value={'Inactive'}>Inactive</option>
                            </select>
                        </div>
                        <div className='w-full lg:w-[49%]'>
                            
                            <select className='bg-white p-3 w-full border-2 rounded-md border-gray-200' type='text' placeholder=''>
                                
                                <option value={'Active'}>United State of America</option>
                                <option value={'Inactive'}>Inactive</option>
                            </select>
                        </div>
                        
                    </div>
                </div><br></br>

                <div className='space-y-4'>
                    <p className='text-xl font-bold mb-4'>Billing Address</p>
                    <p>Address and contact information for tax and invoicing purposes</p>
                    <div className='flex justify-between flex-wrap'>
                        <div className='w-full lg:w-[49%]'>
                            <input className='p-3 w-full border-2 rounded-md border-gray-200' type='text' placeholder='Address 1' />
                        </div>
                        <div className='w-full lg:w-[49%]'>
                            <input className='p-3 w-full border-2 rounded-md border-gray-200' type='text' placeholder='Address 2' />
                        </div>
                        
                    </div>
                    

                    <div className='flex justify-between flex-wrap'>
                        <div className='w-full lg:w-[49%]'>
                            
                            <input className='p-3 w-full border-2 rounded-md border-gray-200' type='text' placeholder='City' />
                        </div>
                        <div className='w-full lg:w-[49%]'>
                            <input className='p-3 w-full border-2 rounded-md border-gray-200' type='text' placeholder='Zip Code' />
                        </div>
                        
                    </div>
                    
                    <div className='flex justify-between flex-wrap'>
                        <div className='w-full lg:w-[49%]'>
                            
                            <select className='bg-white p-3 w-full border-2 rounded-md border-gray-200' type='text' placeholder=''>
                                
                                <option value={'Active'}>Alabama</option>
                                <option value={'Inactive'}>Inactive</option>
                            </select>
                        </div>
                        <div className='w-full lg:w-[49%]'>
                            
                            <select className='bg-white p-3 w-full border-2 rounded-md border-gray-200' type='text' placeholder=''>
                                
                                <option value={'Active'}>United State of America</option>
                                <option value={'Inactive'}>Inactive</option>
                            </select>
                        </div>
                        
                    </div>
                </div>

                <br></br>
                <div className='flex justify-start'>
                    <div onClick={handleHubmit} className='p-3 cursor-pointer bg-blue-700 text-white w-full lg:w-3/12 border-2 rounded-md flex justify-center items-center'>
                        <p className='mr-2 uppercase'>Save Changes</p>
                        
                    </div>
            
                </div>
                <br></br>

            </div>
        </div>
    );
}

export default Address;
