import React, { useCallback, useEffect, useState } from 'react';
import Sidebar from './sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-regular-svg-icons';
import { faArrowRight, faUser } from '@fortawesome/free-solid-svg-icons';
import SimpleMDE, { SimpleMdeReact } from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";
import MarkdownIt from 'markdown-it';
import { apiURL } from '../../app/lib';
import { useSelector } from 'react-redux';
import { isjwtExpire } from '../../app/jwtcheck';
import { ToastContainer, toast } from 'react-toastify';
import Message from '../headers/Message';
import SubscriptionsTable from './subscriptions/Subscriptiontable';
import htmlToMarkdown from '@wcj/html-to-markdown';

const Subscription = () => {
    const [value, setValue] = useState("Initial value");
    const [packages, setpackages] = useState([]);
    const [isLoadingSubmit, setisLoadingSubmit] = useState(false);
    const [isContentLoading, setisContentLoading] = useState(false);
    const [isEditModon, setisEditModon] = useState(false);
    const {user} = useSelector(state=>state.login)
    const [formData, setformData] = useState({
        name:'',
        price:0,
        description:value,
        package_type:'',
        discount_price:0,
        user_id:user?.result[0]?.id
    });
    
    const handleHubmit=()=>{
        if(formData.name==='' || formData.package_type==='' || formData.description===''){
            toast("Empty Input Not Accepted!")
            return 0;
        }
        setisLoadingSubmit(true)
        fetch(apiURL+"/insert-subscription",{
            method:'POST',
            headers:{
                "Content-Type":"application/json",
                "Authorization":user?.token
            },
            body:JSON.stringify(formData)
        })
        .then((res)=>res.json())
        .then((result)=>{
            if(result.status===200){
                toast("Package Saved!")
                setformData({
                    name:'',
                    price:0,
                    description:'',
                    package_type:'',
                    discount_price:0,
                    user_id:user?.result[0]?.id
                })
                setValue('')
                fetchAllPackage()
            }else{
                toast(result?.message)
            }
            setisLoadingSubmit(false)
            
        })
        .catch(()=>{
            toast("Package Not Saved!")
            setisLoadingSubmit(false)
        })
      

    }

    const fetchAllPackage = ()=>{
        setisContentLoading(true)
        fetch(apiURL+"/packages",{
            method:'POST',
            headers:{
                "Content-Type":"application/json",
                "Authorization":user?.token
            }
        })
        .then((res)=>res.json())
        .then((result)=>{
            if(result.status===200){
                setpackages(result?.result)
            }else{
                toast(result?.message)
            }
            setisContentLoading(false)
            
        })
        .catch(()=>{
            toast("Package Not Fetch!")
            setisContentLoading(false)
        })
    }

    const DeletePackage = (id)=>{
        const confirmDelete = window.confirm("Are you sure you want to delete?");
        if(!confirmDelete){
            return 0;
        }
        fetch(apiURL+"/delete-packages/"+id,{
            method:'POST',
            headers:{
                "Content-Type":"application/json",
                "Authorization":user?.token
            },
            body:JSON.stringify({user_id:user?.result[0]?.id})
        })
        .then((res)=>res.json())
        .then((result)=>{
           
            if(result.status===200){
                toast(result?.message)
                fetchAllPackage()
            }else{
                toast(result?.message)
            }
           
            
        })
        .catch(()=>{
            toast("Package Not Deleted!")
            
        })
    }

    const HandleEdit = ()=>{
        if(formData.name==='' || formData.package_type==='' || formData.description===''){
            toast("Empty Input Not Accepted!")
            return 0;
        }
        setisLoadingSubmit(true)
        fetch(apiURL+"/edit-package/"+formData?.id,{
            method:'POST',
            headers:{
                "Content-Type":"application/json",
                "Authorization":user?.token
            },
            body:JSON.stringify(formData)
        })
        .then((res)=>res.json())
        .then((result)=>{
            if(result.status===200){
                toast(result?.message)
                setformData({
                    name:'',
                    price:0,
                    description:'',
                    package_type:'',
                    discount_price:0,
                    user_id:user?.result[0]?.id
                })
                setValue('')
                setisEditModon(false)
                fetchAllPackage()
            }else{
                toast(result?.message)
            }
            setisLoadingSubmit(false)
            
        })
        .catch(()=>{
            toast("Package Not Saved!")
            setisLoadingSubmit(false)
        })
    }

    const EditPackage = (info)=>{
        htmlToMarkdown({ html: info.description }).then((r)=>{
            setisEditModon(true)
            setValue(r)
            setformData({
                name:info.name,
                price:info.price,
                description:r,
                package_type:info.package_type,
                discount_price:info.discount_price,
                user_id:user?.result[0]?.id,
                id:info.id
            })
            // console.log(r);
        })
        
        

    }
    const cancelEdit = ()=>{
       
        setisEditModon(false)
        setformData({
            name:'',
            price:0,
            description:value,
            package_type:'',
            discount_price:0,
            user_id:user?.result[0]?.id
        })
    }
    
    const onChange = useCallback((value) => {
        const md = new MarkdownIt();
        const html = md.render(value);
        setValue(html);
        
      }, []);

      useEffect(() => {
        setformData({...formData,description:value})
      }, [value]);

      useEffect(() => {
        fetchAllPackage()
      }, []);
    return (
        <div className='flex bg-[#f7f9fc]'>
            
            <Sidebar/>
            
            <div className='w-full lg:w-4/5 p-5 mt-16 lg:p-10 text-left'>
                <div className='border-b pb-2 flex justify-between'>
                    <p className='text-xl font-bold'>Subscriptions </p>
                    <FontAwesomeIcon size='xl' icon={faUser} />
                </div>
                <br></br>
               


                {/* <div className='flex items-center '>
                    <div className='text-center w-48 h-48 flex justify-center items-center border-dashed border-2 p-4 border-gray-400 relative'>
                        <div className='w-fit h-fit'>
                            <FontAwesomeIcon size='2xl' color='gray' icon={faImage} />
                            <p className='text-gray-600'>Click to upload an image</p>
                        </div>
                        <input type='file' className='absolute bg-black h-full w-full opacity-0' />
                    </div>
                    <div className='w-full lg:w-1/3 ml-0 lg:ml-6'>
                        <p><strong>Photo</strong></p>
                        <p>Image must be at least 800 x 800px. File formats JPG or PNG.</p>
                    </div>
                </div> */}

                {/* <br></br> */}

                <div className='space-y-4'>
                    <div className='flex justify-between flex-wrap'>
                        <div className='w-full'>
                            <p>Package Name:</p>
                            <input value={formData.name} onChange={(e)=>setformData({...formData,name:e.target.value})} className='p-3 w-full border-2 rounded-md border-gray-200' type='text' placeholder='Package Name' />
                        </div>
                        
                    </div>
                    <div className='flex justify-between flex-wrap'>
                        <div className='w-full lg:w-full'>
                            <p>Package Type</p>
                            <select value={formData.package_type} onChange={(e)=>setformData({...formData,package_type:e.target.value})}  className='bg-white p-3 w-full border-2 rounded-md border-gray-200' type='text' placeholder=''>
                                <option selected >Select type</option>
                                <option selected={formData?.package_type==='Monthly'} value={'Monthly'}>Monthly</option>
                                <option selected={formData?.package_type==='Yearly'} value={'Yearly'}>Yearly</option>
                            </select>
                        </div>
                    </div>
                    

                    {formData.package_type!=='Free'&&<div className='flex justify-between flex-wrap'>
                        <div className='w-full lg:w-[49%]'>
                            <p>Package Price</p>
                            <input value={formData.price} onChange={(e)=>setformData({...formData,price:e.target.value})}  className='p-3 w-full border-2 rounded-md border-gray-200' type='number' placeholder='Package Price' />
                        </div>
                        <div className='w-full lg:w-[49%]'>
                            <p>Discount Price</p>
                            <input value={formData.discount_price} onChange={(e)=>setformData({...formData,discount_price:e.target.value})}  className='p-3 w-full border-2 rounded-md border-gray-200' type='number' placeholder='Discount Price' />
                        </div>
                        
                    </div>}
                    <div className='flex justify-between flex-wrap'>
                        <div className='w-full'>
                            <SimpleMDE  value={value} onChange={onChange} />
                        </div>
                    </div>
                    
                </div>

                <br></br>
                <div className='flex justify-start'>
                    {!isEditModon?<div  disabled={isLoadingSubmit} onClick={handleHubmit} className='p-3 cursor-pointer bg-blue-700 text-white w-full lg:w-3/12 border-2 rounded-md flex justify-center items-center'>
                        <p className='mr-2 uppercase'>{isLoadingSubmit?'Loading...':'Save Changes'}</p>
                        
                    </div>:
                    <div className='w-full flex space-x-5'>
                        <div  disabled={isLoadingSubmit} onClick={HandleEdit} className='p-3 cursor-pointer bg-green-700 text-white w-full lg:w-3/12 border-2 rounded-md flex justify-center items-center'>
                            <p className='mr-2 uppercase'>{isLoadingSubmit?'Loading...':'Save Edits'}</p>
                            
                        </div>
                        <button onClick={cancelEdit}>Cancel Edit</button>
                    </div>
                    }
                </div>
                <br></br>
                <hr></hr>
                <br></br>
                <div className='border-b pb-2 flex justify-between'>
                    <p className='text-xl font-bold'> All Subscriptions </p>
                    <FontAwesomeIcon size='xl' icon={faUser} />
                </div>
                <div className='relative'>
                    {
                        isContentLoading&&
                        <div className='absolute bg-[rgba(0,0,0,0.2)] w-full left-0 top-0 h-full z-50 p-10'><Message message={"Loading..."} /></div>
                    }
                    {
                        !isContentLoading&&
                        <SubscriptionsTable EditPackage={EditPackage} DeletePackage={DeletePackage} subscriptions={packages} />
                    }
                </div>

            </div>
            


        </div>
    );
}

export default Subscription;
