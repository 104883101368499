import React, { useCallback, useEffect, useState } from 'react';
import Sidebar from './sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-regular-svg-icons';
import { faArrowRight, faCloudMoonRain, faUser } from '@fortawesome/free-solid-svg-icons';
import SimpleMDE, { SimpleMdeReact } from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";
import MarkdownIt from 'markdown-it';
import { apiURL } from '../../app/lib';
import { useSelector } from 'react-redux';
import { isjwtExpire } from '../../app/jwtcheck';
import { ToastContainer, toast } from 'react-toastify';
import Message from '../headers/Message';
import SubscriptionsTable from './subscriptions/Subscriptiontable';

import TasksTable from './tasks/tasksTable';
import { Link } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import CoursesTable from './courses/Coursetable';

const Courses = () => {
    const [value, setValue] = useState("");
    const [packages, setpackages] = useState([]);
    const [isLoadingSubmit, setisLoadingSubmit] = useState(false);
    const [isContentLoading, setisContentLoading] = useState(false);
    const [subscription_group, setsubscription_group] = useState('');
    const [isEditModon, setisEditModon] = useState(false);
    const {user} = useSelector(state=>state.login)
    const [categories, setcategories] = useState([]);
    const [subscriptions, setsubscriptions] = useState([]);
    const [formData, setformData] = useState({
        task_title:'',
        points:0,
        task_limit:0,
        description:value,
        subcription_group:'',
        category:'',
        user_id:user?.result[0]?.id,
        isLimited:1,
        isCourse:1,
        url:""
    });
    
    const handleHubmit=()=>{
        console.log(formData);
        if(formData.task_title==='' || formData.category==='' || formData.description==='' || formData.url===''){
            toast("Empty Input Not Accepted!")
            return 0;
        }
        
        
        setisLoadingSubmit(true)
        fetch(apiURL+"/insertcourse",{
            method:'POST',
            headers:{
                "Content-Type":"application/json",
                "Authorization":user?.token
            },
            body:JSON.stringify(formData)
        })
        .then((res)=>res.json())
        .then((result)=>{
            if(result.status===200){
                toast(result.message)
                setformData({
                    task_title:'',
                    points:0,
                    description:value,
                    subcription_group:'',
                    category:0,
                    user_id:user?.result[0]?.id,
                    isLimited:1,
                    isCourse:1,
                    url:""
                })
                setValue('')
                setsubscription_group('')
                fetchAllPackage()
            }else{
                console.log(result);
                toast(result?.message)
            }
            setisLoadingSubmit(false)
            
        })
        .catch(()=>{
            toast("Package Not Saved!")
            setisLoadingSubmit(false)
        })
      

    }

    const fetchCategory = ()=>{
        setisContentLoading(true)
        fetch(apiURL+"/common",{
            method:'POST',
            headers:{
                "Content-Type":"application/json",
                "Authorization":user?.token
            },
            body:JSON.stringify({
                data:"",
                sql:`SELECT * FROM categories`
            })
        })
        .then((res)=>res.json())
        .then((result)=>{
            if(result.status===200){
                console.log(result.result);
                setcategories(result?.result)
            }else{
                toast(result?.message)
            }
            setisContentLoading(false)
            
        })
        .catch(()=>{
            toast("Package Not Fetch!")
            setisContentLoading(false)
        })
    }

    const fetchSubscription = ()=>{
        setisContentLoading(true)
        fetch(apiURL+"/common",{
            method:'POST',
            headers:{
                "Content-Type":"application/json",
                "Authorization":user?.token
            },
            body:JSON.stringify({
                data:"",
                sql:`SELECT * FROM packages`
            })
        })
        .then((res)=>res.json())
        .then((result)=>{
            if(result.status===200){
                console.log(result.result);
                setsubscriptions(result?.result)
            }else{
                toast(result?.message)
            }
            setisContentLoading(false)
            
        })
        .catch(()=>{
            toast("Package Not Fetch!")
            setisContentLoading(false)
        })
    }
    const addPackage = (packageName)=>{
        const concateString = packageName+(subscription_group&&",")+subscription_group;
        setsubscription_group(concateString)
        setformData({...formData,subcription_group:concateString})
        console.log(concateString);
    }
    const remoVePackage = (packageName)=>{
        let RemovedTxt = subscription_group.split(",").filter((packname)=>packname!==packageName&&packname)
        setsubscription_group(RemovedTxt.toString())
        setformData({...formData,subcription_group:RemovedTxt.toString()})
        console.log(RemovedTxt.toString());
    }
    const fetchAllPackage = ()=>{
        setisContentLoading(true)
        fetch(apiURL+"/tasks/courses",{
            method:'POST',
            headers:{
                "Content-Type":"application/json",
                "Authorization":user?.token
            },
            body:JSON.stringify({user_id:user?.result[0]?.id})
        })
        .then((res)=>res.json())
        .then((result)=>{
            if(result.status===200){
                console.log(result.result);
                setpackages(result?.result)
            }else{
                toast(result?.message)
            }
            setisContentLoading(false)
            
        })
        .catch(()=>{
            toast("Package Not Fetch!")
            setisContentLoading(false)
        })
    }

    const DeletePackage = (id)=>{
        const confirmDelete = window.confirm("Are you sure you want to delete?");
        if(!confirmDelete){
            return 0;
        }
        fetch(apiURL+"/delete-tasks-course/"+id,{
            method:'POST',
            headers:{
                "Content-Type":"application/json",
                "Authorization":user?.token
            },
            body:JSON.stringify({user_id:user?.result[0]?.id})
        })
        .then((res)=>res.json())
        .then((result)=>{
           
            if(result.status===200){
                toast(result?.message)
                fetchAllPackage()
            }else{
                toast(result?.message)
            }
           
            
        })
        .catch(()=>{
            toast("Task Not Deleted!")
            
        })
    }

    const HandleEdit = ()=>{
        if(formData.name==='' || formData.package_type==='' || formData.description===''){
            toast("Empty Input Not Accepted!")
            return 0;
        }
        setisLoadingSubmit(true)
        fetch(apiURL+"/edit-course/"+formData?.id,{
            method:'POST',
            headers:{
                "Content-Type":"application/json",
                "Authorization":user?.token
            },
            body:JSON.stringify(formData)
        })
        .then((res)=>res.json())
        .then((result)=>{
            if(result.status===200){
                toast(result?.message)
                setformData({
                    task_title:'',
                    points:0,
                    description:value,
                    subcription_group:'',
                    category:0,
                    user_id:user?.result[0]?.id,
                    isLimited:1,
                    isCourse:1,
                    url:""
                })
                setValue('')
                setisEditModon(false)
                fetchAllPackage()
            }else{
                toast(result?.message)
            }
            setisLoadingSubmit(false)
            
        })
        .catch(()=>{
            toast("Package Not Saved!")
            setisLoadingSubmit(false)
        })
    }

    const EditPackage = (info)=>{
        // htmlToMarkdown({ html: info.description }).then((r)=>{
            setisEditModon(true)
            setValue(info.description)
            setsubscription_group(info.subcription_group)
            setformData({
                task_title:info.task_title,
                points:info.points,
                task_limit:info.task_limit,
                description:info.description,
                subcription_group:info.subcription_group,
                category:info.category,
                user_id:user?.result[0]?.id,
                id:info.task_id,
                isLimited:info?.isLimited,
                isCourse:1,
                url:info?.url //course url
            })
            // console.log(r);
        // })
        
        

    }
    const cancelEdit = ()=>{
       
        setisEditModon(false)
        setformData({
            task_title:'',
            points:0,
            description:value,
            subcription_group:'',
            category:0,
            user_id:user?.result[0]?.id,
            isCourse:1,
            url:""
        })
    }
    
    // const onChange = useCallback((value) => {
    //     const md = new MarkdownIt();
    //     const html = md.render(value);
    //     setValue(html);
        
    //   }, []);

      useEffect(() => {
        setformData({...formData,description:value})
      }, [value]);

      useEffect(() => {
        fetchAllPackage()
        fetchCategory()
        fetchSubscription()
      }, []);
    return (
        <div className='flex bg-[#f7f9fc]'>
            
            <Sidebar/>
            
            <div className='w-full lg:w-4/5 p-5 mt-16 lg:p-10 text-left'>
                <div className='border-b pb-2 flex justify-between'>
                    <p className='text-xl font-bold'>Courses </p>
                    <FontAwesomeIcon size='xl' icon={faCloudMoonRain} />
                </div>
                <br></br>
               


                {/* <div className='flex items-center '>
                    <div className='text-center w-48 h-48 flex justify-center items-center border-dashed border-2 p-4 border-gray-400 relative'>
                        <div className='w-fit h-fit'>
                            <FontAwesomeIcon size='2xl' color='gray' icon={faImage} />
                            <p className='text-gray-600'>Click to upload an image</p>
                        </div>
                        <input type='file' className='absolute bg-black h-full w-full opacity-0' />
                    </div>
                    <div className='w-full lg:w-1/3 ml-0 lg:ml-6'>
                        <p><strong>Photo</strong></p>
                        <p>Image must be at least 800 x 800px. File formats JPG or PNG.</p>
                    </div>
                </div> */}

                {/* <br></br> */}

                <div className='space-y-4'>
                    <div className='flex justify-between flex-wrap'>
                        <div className='w-full'>
                            <p>Course Title:</p>
                            <input value={formData.task_title} onChange={(e)=>setformData({...formData,task_title:e.target.value})} className='p-3 w-full border-2 rounded-md border-gray-200' type='text' placeholder='Course title here' />
                        </div>

                        <div className='w-full'>
                            <p>Course Link:</p>
                            <input value={formData.url} onChange={(e)=>setformData({...formData,url:e.target.value})} className='p-3 w-full border-2 rounded-md border-gray-200' type='text' placeholder='Course Link' />
                        </div>
                        
                    </div>
                    <div className='flex justify-between flex-wrap'>
                        <div className='w-full lg:w-[49%]'>
                            <p>Category <Link className='text-xs text-blue-500' to={'/category'}>Add Category</Link></p>
                            <select required value={formData.category} onChange={(e)=>setformData({...formData,category:e.target.value})}  className='bg-white p-3 w-full border-2 rounded-md border-gray-200' type='text' placeholder=''>
                                <option selected >Select type</option>
                                {
                                    categories&&Array.isArray(categories)&&categories
                                    .map((category)=>{
                                        return <option value={category?.name}>{category?.name}</option>
                                    })
                                }
                                
                            </select>
                        </div>
                        <div className='w-full lg:w-[49%]'>
                            <p>Limited Submittion</p>
                            <select disabled defaultValue={formData?.isLimited} onChange={(e)=>setformData({...formData,isLimited:e.target.value})} className='bg-white p-3 w-full border-2 rounded-md border-gray-200'>
                                <option value={'1'}>Limited</option>
                                <option value={'0'}>Unlimited</option>
                            </select>
                        </div>
                    </div>
                    

                    {<div className='flex justify-between flex-wrap'>
                        <div className='flex justify-between w-full'>
                            <div className='w-full lg:w-[49%]'>
                                <p>Course Points</p>
                                <input disabled value={formData.points} onChange={(e)=>setformData({...formData,points:e.target.value})}  className='p-3 w-full border-2 rounded-md border-gray-200' type='number' placeholder='Package Price' />
                            </div>
                            <div className='w-full lg:w-[49%]'>
                                <p>Task Limits</p>
                                <input disabled value={formData.task_limit} onChange={(e)=>setformData({...formData,task_limit:e.target.value})}  className={`p-3 w-full border-2 rounded-md ${formData?.isLimited==='0'&&"cursor-not-allowed bg-gray-300"} border-gray-200`} type='number' placeholder='Task Limit' />
                            </div>
                        </div>
                        <div className='w-full lg:w-[100%]'>
                            <p>Course For-</p>
                            <select onChange={(e)=>addPackage(e.target.value)}  className='bg-white p-3 w-full border-2 rounded-md border-gray-200' type='text' placeholder=''>
                                <option selected >Select Subscription</option>
                                <option value={'Free'} >Free</option>
                                {
                                    subscriptions&&Array.isArray(subscriptions)&&subscriptions
                                    .map((subscription)=>{
                                        return <option value={subscription?.name}>{subscription?.name}</option>
                                    })
                                }
                            </select>
                            {subscription_group&&<div className='p-3 mt-2 bg-blue-100 rounded-md'>
                                <p>Selected Packages:</p>
                                <div className='flex flex-wrap'>

                                    {
                                        subscription_group&&subscription_group.split(",").map((packageName)=>{
                                            return <p className='px-2 m-1 py-1 bg-blue-500 border rounded-xl text-white text-xs w-fit'>{packageName} <span className='cursor-pointer' onClick={()=>remoVePackage(packageName)}> &times;</span></p>
                                        })
                                    }

                                    
                                    

                                </div>
                            </div>}
                        </div>
                        
                    </div>}
                    
                    <div className='flex justify-between flex-wrap'>
                        <div className='w-full'>
                            <p>Course Description</p>
                            {/* <SimpleMDE  value={value} onChange={onChange} /> */}
                            <ReactQuill theme="snow" style={{height:'250px',backgroundColor:'#fff'}} value={value} onChange={setValue} />
                        </div>
                    </div>
                    
                </div>

                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <div className='flex justify-start'>
                    {!isEditModon?<div  disabled={isLoadingSubmit} onClick={handleHubmit} className='p-3 cursor-pointer bg-blue-700 text-white w-full lg:w-3/12 border-2 rounded-md flex justify-center items-center'>
                        <p className='mr-2 uppercase'>{isLoadingSubmit?'Loading...':'Save Changes'}</p>
                        
                    </div>:
                    <div className='w-full flex space-x-5'>
                        <div  disabled={isLoadingSubmit} onClick={HandleEdit} className='p-3 cursor-pointer bg-green-700 text-white w-full lg:w-3/12 border-2 rounded-md flex justify-center items-center'>
                            <p className='mr-2 uppercase'>{isLoadingSubmit?'Loading...':'Save Edits'}</p>
                            
                        </div>
                        <button onClick={cancelEdit}>Cancel Edit</button>
                    </div>
                    }
                </div>
                <br></br>
                <hr></hr>
                <br></br>
                <div className='border-b pb-2 flex justify-between'>
                    <p className='text-xl font-bold'> All Courses </p>
                    <FontAwesomeIcon size='xl' icon={faUser} />
                </div>
                <div className='relative'>
                    {
                        isContentLoading&&
                        <div className='absolute bg-[rgba(0,0,0,0.2)] w-full left-0 top-0 h-full z-50 p-10'><Message message={"Loading..."} /></div>
                    }
                    {
                        !isContentLoading&&
                        <CoursesTable EditPackage={EditPackage} DeletePackage={DeletePackage} subscriptions={packages} />
                    }
                </div>

            </div>
            


        </div>
    );
}

export default Courses;
