import React, {useState} from 'react';
import logo from "../../Images/reveallogo.png"
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faChevronDown, faComputer, faFileCircleCheck, faFingerprint, faGear, faPowerOff, faShield, faUser, faUserCircle, faUserDoctor } from '@fortawesome/free-solid-svg-icons';
import {faBell,faQuestionCircle} from "@fortawesome/free-regular-svg-icons"
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../features/login/loginSlice';
const Header = ({handlBar}) => {
    const [displaynotif, setdisplaynotif] = useState(false);
    const [profileOption, setprofileOption] = useState(false);
    const {user} = useSelector(state=>state.login)
    const dispatch = useDispatch();
    const showNotificationPanel = ()=>{
        setdisplaynotif(!displaynotif)
    }

    const showProfileOption = ()=>{
        setprofileOption(!profileOption)
    }
    const logoutFun = ()=>{
        dispatch(logout())
        sessionStorage.removeItem("login")
    }
    
    return (
        <div className='bg-blue-100 p-5 px-5 lg:px-10 flex fixed top-0 left-0 w-full z-50 items-center'>
            <div className='pr-14 w-1/2 lg:w-1/6'>
                <img style={{width:'150px'}} src={logo} />
            </div>

            {/* Menu options */}
            <div className='hidden lg:flex space-x-8 w-7/12'>
                <li className='text-blue-700'>
                    <FontAwesomeIcon icon={faComputer} />
                    <Link to="/"> Dashboard</Link>
                </li>
                {/* <li className='text-blue-700'>
                    <FontAwesomeIcon icon={faFingerprint} />
                    <Link to="/">  Order Background Check</Link>
                </li>
                <li className='text-blue-700'>
                    <FontAwesomeIcon icon={faUserDoctor} />
                    <Link to="/"> Candidates</Link>
                </li>
                <li className='text-blue-700'>
                    <FontAwesomeIcon icon={faFileCircleCheck} />
                    <Link to="/"> Packages</Link>
                </li> */}
            </div>

            {/* Right side options */}
            <div className='flex space-x-5 items-center w-1/2 lg:w-2/6 justify-end'>
                <li onClick={handlBar} className='text-blue-700 block lg:hidden'>
                    <FontAwesomeIcon size='lg' icon={faBars} />
                </li>
                {/* <li onClick={showNotificationPanel} className='text-blue-700 relative'>
                
                    <FontAwesomeIcon size='lg' icon={faBell} /> 
                    <p className='absolute -top-2 -right-2 bg-blue-700 text-white h-4 w-4 rounded-full flex justify-center items-center' style={{fontSize:'9px'}}>5</p>
                    <div className={`absolute w-72 ${!displaynotif&&'hidden'} scrollbarThin shadow-md bg-white z-40 -top-4 -left-52`} style={{height:'400px'}}>
                        <div className='bg-blue-600 p-2 flex justify-between items-center'>
                            <h4 className='text-xl text-white'>Notification</h4>
                            <p onClick={showNotificationPanel} className='text-white text-2xl cursor-pointer'>&times;</p>
                        </div>
                        <div className='h-80 overflow-y-scroll'>
                            <div className='border-b-2 text-left p-4'>
                                <p className='text-xs font-bold text-blue-600 mb-1'>Grey pitters</p>
                                <p className='text-xs text-black'>We should talk about this at launch</p>
                                <p className='text-xs  text-black'>Jan,01,2024</p>
                            </div>
                            <div className='border-b-2 text-left p-4'>
                                <p className='text-xs font-bold text-blue-600 mb-1'>Grey pitters</p>
                                <p className='text-xs text-black'>We should talk about this at launch</p>
                                <p className='text-xs  text-black'>Jan,01,2024</p>
                            </div>
                            <div className='border-b-2 text-left p-4'>
                                <p className='text-xs font-bold text-blue-600 mb-1'>Grey pitters</p>
                                <p className='text-xs text-black'>We should talk about this at launch</p>
                                <p className='text-xs  text-black'>Jan,01,2024</p>
                            </div>
                            <div className='border-b-2 text-left p-4'>
                                <p className='text-xs font-bold text-blue-600 mb-1'>Grey pitters</p>
                                <p className='text-xs text-black'>We should talk about this at launch</p>
                                <p className='text-xs  text-black'>Jan,01,2024</p>
                            </div>
                            <div className='border-b-2 text-left p-4'>
                                <p className='text-xs font-bold text-blue-600 mb-1'>Grey pitters</p>
                                <p className='text-xs text-black'>We should talk about this at launch</p>
                                <p className='text-xs  text-black'>Jan,01,2024</p>
                            </div>
                            <div className='border-b-2 text-left p-4'>
                                <p className='text-xs font-bold text-blue-600 mb-1'>Grey pitters</p>
                                <p className='text-xs text-black'>We should talk about this at launch</p>
                                <p className='text-xs  text-black'>Jan,01,2024</p>
                            </div>
                            <div className='border-b-2 text-left p-4'>
                                <p className='text-xs font-bold text-blue-600 mb-1'>Grey pitters</p>
                                <p className='text-xs text-black'>We should talk about this at launch</p>
                                <p className='text-xs  text-black'>Jan,01,2024</p>
                            </div>
                        </div>
                        <div className='bg-gray-300 p-1'>
                            <Link className='text-xs'>View More</Link>
                        </div>
                        
                    </div>
                </li> */}
                <li onClick={showProfileOption} className='text-blue-700 cursor-pointer flex items-center relative'>
                    <img className='h-10 w-10 rounded-full mr-2' src='http://approvals.gtectsystems.com/reveal/webapp/images/profile.png'  />
                    <p className='hidden lg:block text-sm font-bold'>{user.result[0]?.full_name} <FontAwesomeIcon icon={faChevronDown} /></p>
                    {/* <FontAwesomeIcon className='absolute -bottom-1 left-6 shadow-md text-gray-800 h-4 w-4 rounded-full flex justify-center items-center' icon={faShield} /> */}
                    <div className={`absolute w-44 ${!profileOption&&'hidden'} border-2 rounded-md shadow-md bg-white z-40 top-12 -left-32 lg:-left-5`}>
                        <div onClick={showProfileOption} className=' p-2 text-gray-950'>
                            {/* <div className='border-b-2 text-left w-full p-2'>
                                <p className='text-xs font-bold'>
                                    <FontAwesomeIcon icon={faUser} /> Profile
                                </p>
                            </div>
                            <div className='border-b-2 text-left w-full p-2'>
                                <p className='text-xs font-bold'>
                                    <FontAwesomeIcon icon={faGear} /> Settings
                                </p>
                            </div> */}
                            <div className=' text-left w-full p-2'>
                                <p onClick={logoutFun} className='text-xs font-bold'>
                                    <FontAwesomeIcon icon={faPowerOff} /> Logout
                                </p>
                            </div>
                        </div>
                    </div>
                </li>
            </div>
        </div>
    );
}

export default Header;
