import React from 'react'
import loginpage from "../../Images/login.png"
import logo from "../../Images/reveallogo.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons'

import { useNavigate  } from 'react-router-dom'
import SelectUser from './signup_compo/select_user'

export default function Getstarted() {
    
  return (
    <div className='flex'>
        <div className='w-1/2 h-screen hidden lg:flex justify-center items-center' style={{background: "linear-gradient(to bottom, rgba(212,224,250,1) 0%,rgba(244,236,247,1) 50%,rgba(244,236,247,1) 50%,rgba(251,247,252,1) 100%)"}}>
            <img className='w-full' src={loginpage} alt='login'/>
        </div>
        <div className='w-full lg:w-1/2 p-10'>
            <div className='flex justify-center pt-20'>
                <img src={logo} alt='Logo' />
            </div>
            <SelectUser/>
            <div className='h-56 lg:h-0'>

            </div>
        </div>
    </div>
  )
}
