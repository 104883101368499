import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React from 'react';


const CoursesTable = ({ EditPackage, DeletePackage, subscriptions }) => {
  return (
    <div>
      <table className='w-full'>
        <thead>
          <tr>
            <th>ID</th>
            <th>Title</th>
            <th>URL</th>
            <th>Category</th>
            <th>Subscription Group</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {subscriptions.map((subscription,index) => (
            <tr className='border-b' key={index}>
              <td>{index+1}</td>
              <td className='w-[20%] text-xs'>{subscription.task_title}</td>
              <td className='w-[25%] text-xs'>
                {subscription.url}
               
              </td>
              <tb>
                <p className='px-2 m-1 py-1 bg-red-500 border rounded-xl text-white text-[9px] w-fit'>{subscription?.category}</p>
                
              </tb>
              
              <td>
                <div className='flex flex-wrap'>
                  {
                    subscription.subcription_group&&subscription.subcription_group.split(",").map((packageName)=>{
                        return <p className='px-2 m-1 py-1 bg-blue-500 border rounded-xl text-white text-xs w-fit'>{packageName}</p>
                    })
                  }
                </div>
              </td>
              <td className='flex space-x-2'>
                <p onClick={()=>EditPackage(subscription)} className='text-xs cursor-pointer bg-green-200 text-green-700 border border-green-600 px-3 py-1 rounded-xl w-fit'><FontAwesomeIcon icon={faEdit} /> Edit</p>
                <p onClick={()=>DeletePackage(subscription.task_id)} className='text-xs cursor-pointer bg-red-200  text-red-700 border border-red-600 px-3 py-1 rounded-xl w-fit'><FontAwesomeIcon icon={faTrash} /> Delete</p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CoursesTable;
