import { faEdit, faMessage } from '@fortawesome/free-regular-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { apiURL } from '../../../app/lib';
import { useSelector, useStore } from 'react-redux';
import { toast } from 'react-toastify';
import SocketIOClient from 'socket.io-client';



const UserTable = ({ EditPackage, DeletePackage, subscriptions,fetchAllPackage }) => {
  const {user} = useSelector(state=>state.login)
  const [Allpackages, setAllpackages] = useState(null);
  const [isContentLoading, setisContentLoading] = useState(false);
  const [selectedPacakge, setselectedPacakge] = useState('');
  const [CheckAll, setCheckAll] = useState(false);
  const [customSelect, setcustomSelect] = useState([]);
  const [isOpenMessage, setisOpenMessage] = useState(true);
  const [messagebox, setmessagebox] = useState('');
  const [updatepoints, setupdatepoints] = useState()
  const [notifMessage, setnotifMessage] = useState('');
  const [inboxMessage, setinboxMessage] = useState('');
  const [title, settitle] = useState('');

  const fetchSubscription = ()=>{
    setisContentLoading(true)
    fetch(apiURL+"/common",{
        method:'POST',
        headers:{
            "Content-Type":"application/json",
            "Authorization":user?.token
        },
        body:JSON.stringify({
            data:"",
            sql:`SELECT * FROM packages`
        })
    })
    .then((res)=>res.json())
    .then((result)=>{
        if(result.status===200){
            console.log(result.result);
            setAllpackages(result?.result)
            
        }else{
            toast(result?.message)
        }
        setisContentLoading(false)
        
    })
    .catch(()=>{
        toast("Package Not Fetch!")
        setisContentLoading(false)
    })
  }
  const changePack = (packageinfo,user_id)=>{
    setisContentLoading(true)
    const packinfo = packageinfo.split(',');
   
    
    fetch(apiURL+"/common",{
        method:'POST',
        headers:{
            "Content-Type":"application/json",
            "Authorization":user?.token
        },
        body:JSON.stringify({
            data:"",
            sql:`UPDATE users SET subscription='${packinfo[0]}', subscription_id=${packinfo[1]} WHERE id=${user_id}`
        })
    })
    .then((res)=>res.json())
    .then((result)=>{
        if(result.status===200){
            console.log(result.result);
            setAllpackages(result?.result)
            fetchAllPackage()
            toast(result?.message)
        }else{
            toast(result?.message)
        }
        setisContentLoading(false)
        
    })
    .catch(()=>{
        toast("Package Not Fetch!")
        setisContentLoading(false)
    })
  }

  const updatePoints = (points,user_id)=>{
    if(!updatepoints){
      return alert("Input empty")
    }
    if(!window.confirm("Are you sure to update points?")){
      return 0;
    }
    setisContentLoading(true)
    
   
    
    fetch(apiURL+"/common",{
        method:'POST',
        headers:{
            "Content-Type":"application/json",
            "Authorization":user?.token
        },
        body:JSON.stringify({
            data:"",
            sql:`UPDATE users SET points='${points}' WHERE id=${user_id}`
        })
    })
    .then((res)=>res.json())
    .then((result)=>{
        if(result.status===200){
            console.log(result.result);
            setAllpackages(result?.result)
            fetchAllPackage()
            toast(result?.message)
        }else{
            toast(result?.message)
        }
        setisContentLoading(false)
        
    })
    .catch(()=>{
        toast("Package Not Fetch!")
        setisContentLoading(false)
    })
  }

  const sendMessage = ()=>{
    if(customSelect.length<1){
      return toast("Please Select the user!")
    }
    if(messagebox===''){
      return toast("Please Write some messages!")
    }
    setisContentLoading(true)
    const numbersare = String(customSelect);
    fetch(apiURL+"/send_message",{
      method:'POST',
      headers:{
          "Content-Type":"application/json",
          "Authorization":user?.token
      },
      body:JSON.stringify({
          message:messagebox,
          phones:numbersare
      })
      
    })
    .then((res)=>res.json())
    .then((result)=>{
        if(result.status===200){
            console.log(result);
            toast(result?.message)
            // console.log(result.result);
            // setAllpackages(result?.result)
            // setcustomSelect([])
            // setCheckAll(false)
        }else{
            toast(result?.message)
        }
        setisContentLoading(false)
        
    })
    .catch(()=>{
        toast("Package Not Fetch!")
        setisContentLoading(false)
    })
  }

  const selectFun = (phone)=>{
    setCheckAll(false)
    let allPhone=[...customSelect];
    
    if(allPhone.includes(phone)){
      allPhone = allPhone.filter((p)=>p!==phone)
    }else{
      if(validatePhoneNumber(phone)){
        allPhone.push(phone)
      }else{
        alert("Number is Invalid!");
      }
      
    }

    setcustomSelect(allPhone)
    // console.log(String(allPhone));
  }

  function validatePhoneNumber(phoneNumber) {
    // Regular expression pattern for phone number validation
    var pattern = /^8801[3-9][0-9]{8}$/;

    // Test if the phone number matches the pattern
    var isValid = pattern.test(phoneNumber);

    // Return true if the phone number is valid, otherwise false
    return isValid;
  }


  const checkAllFun = (v)=>{
    let allUserPhone = [];
    if(v){
      subscriptions.map((user)=>{
        if(validatePhoneNumber("88"+user.phone)){
          allUserPhone.push("88"+user.phone)
        }
        
      })
    }
    setcustomSelect(allUserPhone)
    setCheckAll(v)
  }

  // const handleClick = () => {
  //   if ('Notification' in window) {
  //     Notification.requestPermission().then((permission) => {
  //       if (permission === 'granted') {
  //         new Notification('Hello, World!', {
  //           body: 'This is a notification.',
  //           icon: 'path/to/icon.png', // Optional icon path
  //         });
  //       }
  //     });
  //   }
  // };


  useEffect(() => {
    try {
      const socket = SocketIOClient('https://zas71.dhopaelo.com/', {
      path: '/api/socket.io',
      });

      socket.on(`notify`, (msg) => {
          console.log(msg?.result);    
      });
  } catch (error) {
      console.log(error);
  }
  }, []);

  useEffect(() => {
    fetchSubscription()
  }, []);


  const sendNotif = ()=>{
    if(notifMessage===''){
      return alert("Empty Message!")
    }
    fetch(apiURL+"/send_notification",{
      method:'POST',
      headers:{
          "Content-Type":"application/json",
          "Authorization":user?.token
      },
      body:JSON.stringify({user_id:1,message:notifMessage})
      
    })
    .then((res)=>res.json())
    .then((result)=>{
        if(result.status===200){
            setnotifMessage('')
            toast(result?.message)
            
        }else{
            toast(result?.message)
        }
        
        
    })
    .catch(()=>{
        toast("Package Not Fetch!")
      
    })
  }

  //notificaion in inbox
  const sendNotifinBox = ()=>{
    try {
      fetch(apiURL+"/insert-notification",{
        method:"POST",
        headers:{
          "Content-Type":"application/json",
          "Authorization":user?.token
        },
        body:JSON.stringify({
          message:inboxMessage,
          title:title
        })
      })
      .then((res)=>res.json())
      .then((result)=>{
        if(result?.status!==200){
          return toast(result?.message)
        }
        toast(result?.message)
        settitle("")
        setinboxMessage('')
      })
    } catch (error) {
      toast("Error Found")
    }
  }
  return (
    <div className='relative'>
      <div className='flex'>
        <div className='w-1/2 p-3'>
          <textarea onChange={(e)=>setnotifMessage(e.target.value)} cols={10} className='border-2 p-3 w-full' placeholder='Notification will be send to every active users'></textarea><br></br>
          <button className='px-4 py-2 my-2 bg-green-500 rounded-md' onClick={sendNotif}>Send Push Notif.</button>
          <br></br>
          <br></br>
        </div>

        <div className='w-1/2 p-3'>
          <input className='p-2 w-full mb-3 border-2' onChange={(e)=>settitle(e.target.value)} type='text' placeholder='Short title' />
          <textarea onChange={(e)=>setinboxMessage(e.target.value)} cols={10} className='border-2 p-3 w-full' placeholder='Notification will be send to every active users in their notification box'></textarea><br></br>
          <button className='px-4 py-2 my-2 bg-blue-500 rounded-md' onClick={sendNotifinBox}>Send Notice</button>
          <br></br>
          <br></br>
        </div>
      </div>
      {customSelect.length>0&&!isOpenMessage&&<div onClick={()=>setisOpenMessage(!isOpenMessage)} className='fixed flex justify-center items-center right-9 top-32 h-[70px] w-[70px] shadow-md rounded-full border bg-white p-3 z-[90]'>
        <FontAwesomeIcon size={25} color='green' icon={faMessage} />
      </div>}

      {customSelect.length>0&&isOpenMessage&&<div className='fixed right-6 top-32 w-[400px] shadow-md rounded-md border bg-white p-3 z-[90]'>
        <div>
          <div className='flex justify-between items-center mb-3'>
            <p>Selcted Users ({customSelect.length})</p>
            <p onClick={()=>setisOpenMessage(!isOpenMessage)} className='text-2xl cursor-pointer text-red-500'>&times;</p>
          </div>
          <div className='border p-2 rounded-md flex flex-wrap max-h-48 overflow-y-auto'>
            {
              customSelect.length>0&&customSelect.map((phone,i)=><span onClick={()=>selectFun(phone)} className='py-1 text-xs border rounded h-fit cursor-pointer px-2 bg-green-100 m-1' key={i}>{phone}</span>)
            }
          </div>
          <br></br>
          <p>Message</p>
          <textarea onChange={(e)=>setmessagebox(e.target.value)} className='p-3 border rounded-md h-32 border-green-300 w-full'></textarea>
          <br></br>
          <br></br>
          <button onClick={sendMessage} disabled={isContentLoading} className='bg-green-300 border px-5 py-1'>{isContentLoading?"Sending....":'Send'}</button>
          <br></br>
          <br></br>
        </div>
      </div>}

      
            
      <table className='w-full'>
        <thead>
          <tr>
            <th><input type='checkbox' checked={CheckAll} onChange={()=>checkAllFun(!CheckAll)} /> ID</th>
            <th>Name</th>
            <th>Contact</th>
            <th>Points</th>
            <th>Subscription</th>
            
            <th>Points Update</th>
          </tr>
        </thead>
        <tbody>
          {subscriptions.map((subscription) => (
            <tr className='border-b' key={subscription.id}>
              <td><input checked={customSelect.includes("88"+subscription.phone)} onChange={()=>selectFun("88"+subscription.phone)} type='checkbox' /> {subscription.id}</td>
              <td>{subscription.full_name}</td>
              <td className='text-xs'>
                <p className='text-blue-500'>Email: {subscription.email}</p>
                <p className='text-red-500'>Phone: {subscription.phone}</p>
                <p className='text-green-500'>DOB: {subscription.dob}</p>
              </td>
              <td>
                <p>Points: {subscription.points}</p>
                <p className='text-gray-500'>Join in: {moment(subscription.created_at).format("MMM Do YY") }</p>
              </td>
              <td>
                <div className=''>
                  <p className='text-xs'>Current package: <b>{subscription.subscription}</b></p>
                  {!isContentLoading?<select onChange={(e)=>changePack(e.target.value,subscription.id)}  className='bg-white p-1 border-2 rounded-md text-xs w-fit border-gray-200' type='text' placeholder=''>
                    <option selected >Change Pack</option>
                    <option value={['Free',0]} >Free</option>
                    {
                        Allpackages&&Array.isArray(Allpackages)&&Allpackages
                        .map((packageName)=>{
                            return <option value={[packageName?.name,packageName?.id]}>{packageName?.name}</option>
                        })
                    }
                  </select>:<p className='text-xs'>Updating...</p>}
                </div>
              </td>
              <td className='flex space-x-2'>
                {/* <p onClick={()=>EditPackage(subscription)} className='text-xs cursor-pointer bg-green-200 text-green-700 border border-green-600 px-3 py-1 rounded-xl w-fit'><FontAwesomeIcon icon={faEdit} /> Edit</p>
                <p onClick={()=>DeletePackage(subscription.id)} className='text-xs cursor-pointer bg-red-200  text-red-700 border border-red-600 px-3 py-1 rounded-xl w-fit'><FontAwesomeIcon icon={faTrash} /> Delete</p> */}
                {/* <div>
                  <input onChange={(e)=>setupdatepoints(e.target.value)} className='border-2 p-1 w-28 mt-1' type='number' />
                  <button disabled={isContentLoading} onClick={()=>updatePoints(updatepoints,subscription.id)} className='bg-slate-400 border rounded-md p-1'>Save</button>
                </div> */}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserTable;
