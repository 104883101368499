import React from 'react'
import loginpage from "../../Images/login.png"
import logo from "../../Images/reveallogo.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons'
import { Outlet,Link } from 'react-router-dom'


export default function Forgotpassword() {
  return (
    <div className='flex'>
        <div className='w-1/2 relative h-screen hidden lg:flex justify-center items-center' style={{background: "linear-gradient(to bottom, rgba(212,224,250,1) 0%,rgba(244,236,247,1) 50%,rgba(244,236,247,1) 50%,rgba(251,247,252,1) 100%)"}}>
            <img className='w-full' src={loginpage} alt='login'/>
            <div className='logincover absolute top-10'>
                <h2>
                    <strong>Background</strong>
                    Check made simple
                </h2>
            </div>
        </div>
        <div className='w-full lg:w-1/2 p-10'>
            <div className='flex justify-center pt-20'>
                <img src={logo} alt='Logo' />
            </div>
            <div className='mt-5'>
                <h6 className='text-2xl font-bold' style={{color:"#25253e"}}>Forgot Password</h6>
                <p>Please enter your Email Address to reset password</p>
            </div>
            <div className='flex mt-14 justify-center w-full flex-wrap space-y-5 lg:space-y-0'>
                <div className='w-full lg:w-8/12'>
                    <p className='text-left'>Email Address <span className='text-red-500'>*</span></p>
                    <input className='p-2 w-full border-2 rounded-md border-red-200' type='email' placeholder='Enter your email *' /><br></br><br></br>
                    
                    <input className='p-2 bg-blue-700 text-white w-full lg:w-5/12 border-2 rounded-md' type='submit' value={'SEND EMAIL'} /><br></br><br></br>

                    
                </div>
            </div>
            <div className='h-56 lg:h-0'>

            </div>
        </div>
    </div>
  )
}
